import Flickity from "flickity";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

onDOMContentLoaded.on(() => {
    const pdpFeatures = document.querySelector(
        ".pdp-features__image-container",
    );
    if (!pdpFeatures) {
        return;
    }
    new Flickity(pdpFeatures, {
        watchCSS: true,
        autoPlay: true,
    });
});
