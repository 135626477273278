import React from "react";
import { signupForEmailOffers } from "tsi-common-react/src/api/user";
import { updateGTMUserHash } from "tsi-common-react/src/utils/analytics";
import homeModal from "../../img/home-page/module/home-modal.jpg";
import homeModalClose from "../../img/home-page/module/home-modal-close.jpg";

const TRUE = "true";

interface IProps {}

interface IState {
    hasBeenClosed: boolean;
    email: string;
    errors: string[];
    isCompleted: boolean;
}

export class EmailSignupModal extends React.Component<IProps, IState> {
    localStorageKey = "email-signup-modal-closed";

    public state: IState = {
        hasBeenClosed: this.getHasBeenClosed(),
        email: "",
        errors: [],
        isCompleted: false,
    };

    getHasBeenClosed() {
        try {
            return localStorage[this.localStorageKey] === TRUE;
        } catch (e) {
            console.error(e);
        }
        return false;
    }

    setHasBeenClosed() {
        try {
            localStorage[this.localStorageKey] = TRUE;
        } catch (e) {
            console.error(e);
        }
    }

    closeModal() {
        this.setHasBeenClosed();
        this.setState({
            hasBeenClosed: true,
        });
    }

    onEmailChange(e: React.FormEvent<HTMLInputElement>) {
        this.setState({
            email: e.currentTarget.value,
        });
    }

    onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        // Update USER_HASH in the datalayer
        updateGTMUserHash(this.state.email);

        signupForEmailOffers(this.state.email)
            .then(() => {
                this.setState({ isCompleted: true });

                setTimeout(() => {
                    this.closeModal();
                }, 800);
            })
            .catch((err) => {
                this.setState({
                    errors: err.response.body.email || [
                        "An unexpected error occured.",
                    ],
                });
            });
    }

    render() {
        if (this.state.hasBeenClosed) {
            return null;
        }

        let emailSignupElem: JSX.Element | null = null;

        if (this.state.isCompleted) {
            emailSignupElem = (
                <div className="email-sign-up email-sign-up--no-bottom-margin l-capped-width">
                    Thank you for subscribing!
                </div>
            );
        } else {
            emailSignupElem = (
                <div className="email-sign-up email-sign-up--no-bottom-margin l-capped-width">
                    <form
                        className="email-sign-up__form"
                        onSubmit={(e) => {
                            this.onSubmit(e);
                        }}
                    >
                        <div className="email-sign-up__email email-sign-up__field">
                            <input
                                className="email-sign-up__input email-sign-up__input--white"
                                placeholder="Enter email to get offers"
                                type="email"
                                required={true}
                                value={this.state.email}
                                onChange={(e) => {
                                    this.onEmailChange(e);
                                }}
                            />
                        </div>
                        <div className="email-sign-up__email email-sign-up__submit al-email-signup__submit al-modal-email-signup__submit">
                            <input
                                className="button email-sign-up__button email-sign-up__button--ming-blue"
                                type="submit"
                                value="SUBMIT"
                            />
                            <div className="email-sign-up__diagonal email-sign-up__diagonal--ming-blue"></div>
                        </div>
                    </form>
                    {this.state.errors.map((msg, i) => {
                        return (
                            <p key={i} className="email-sign-up__error">
                                {msg}
                            </p>
                        );
                    })}
                </div>
            );
        }

        return (
            <div className="l-full-width home-email-subscription">
                <div className="l-capped-width">
                    <div className="home-email-subscription__inner">
                        <div className="home-email-subscription__image">
                            <img
                                className="responsive-img home-email-subscription__image-item"
                                src={homeModal}
                                alt=""
                            />
                        </div>
                        <div className="home-email-subscription__content">
                            <div className="home-email-subscription__pre-header">
                                limited time only
                            </div>
                            <div className="home-email-subscription__header">
                                Get a{" "}
                                <span className="home-email-subscription__header--highlight">
                                    <b>FREE</b> TEMPUR-Cloud Pillow
                                </span>{" "}
                                with every topper purchase
                            </div>
                            <div className="home-email-subscription__subscription-form">
                                {emailSignupElem}
                            </div>
                            <div
                                className="home-email-subscription__message"
                                onClick={() => {
                                    this.closeModal();
                                }}
                            >
                                Shop Now
                            </div>
                        </div>
                        <div
                            className="home-email-subscription__close"
                            onClick={() => {
                                this.closeModal();
                            }}
                        >
                            <img
                                className="responsive-img"
                                src={homeModalClose}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
