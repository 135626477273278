import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

// Detect any promo settings loaded at the top of basket
onDOMContentLoaded.on(() => {
    const promo = document.querySelector(".search-nav__promo");
    if (promo) {
        // Apply 27px to basket-app as it'd be fit to design parameters
        const basketApp = document.getElementById("basket-app");
        if (basketApp) {
            basketApp.style.marginTop = "27px";
        }
    }
});
