import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { switchActiveElement } from "../utils/activeElem";
import ScrollMagic from "scrollmagic";

// Tabbed content.
const registerEventListeners_Tabs = () => {
    document
        .querySelectorAll<HTMLAnchorElement>("[data-tabs]")
        .forEach((elem) => {
            elem.addEventListener("click", (event) => {
                event.preventDefault();
                const target = document.querySelector<HTMLElement>(
                    elem.attributes.getNamedItem("href")?.value || "",
                );
                const block = elem.dataset.tabs || "";
                switchActiveElement(elem, `${block}__tab`);
                if (target) {
                    switchActiveElement(target, `${block}__pane`);
                }

                const parent = elem.parentNode?.parentNode;
                if (!parent) {
                    return;
                }
                Array.from(parent.children)
                    .filter((e) => {
                        return e.classList.contains("tabs__tab-list-item");
                    })
                    .forEach((e) => {
                        if (
                            e.children[0].classList.contains(
                                "tabs__tab--is-active",
                            )
                        ) {
                            e.children[0].setAttribute("aria-selected", "true");
                        } else {
                            e.children[0].setAttribute(
                                "aria-selected",
                                "false",
                            );
                        }
                    });
            });
        });

    // Focus previous/next tabs using Left and Right arrow keys
    let focusedTabIndex = 0;
    const tabs = document.querySelectorAll<HTMLElement>(".tabs__tab");
    document.addEventListener("keydown", (e) => {
        switch (e.key) {
            case "ArrowLeft":
                focusPreviousTab();
                break;

            case "ArrowRight":
                focusNextTab();
                break;
        }
    });

    // Activate tabs when they are clicked (also handles Enter and Space keys)
    tabs.forEach((tab: HTMLElement) => {
        tab.addEventListener("click", (e) => {
            const nextTabIndex = Array.prototype.slice
                .call(tabs)
                .indexOf(e.target);
            selectTab(nextTabIndex);
        });
    });

    // Tab selection
    const focusPreviousTab = () => {
        if (focusedTabIndex > 0) {
            focusedTabIndex -= 1;
        } else {
            focusedTabIndex = tabs.length - 1;
        }
        tabs[focusedTabIndex].focus();
    };

    const focusNextTab = () => {
        if (focusedTabIndex < tabs.length - 1) {
            focusedTabIndex += 1;
        } else {
            focusedTabIndex = 0;
        }
        tabs[focusedTabIndex].focus();
    };

    const selectTab = (index: number) => {
        // Deactivate all tabs
        tabs.forEach((tab: HTMLElement) => {
            tab.setAttribute("aria-selected", "false");
            tab.setAttribute("tabindex", "-1");
        });
        // Activate only the requested tab, and set focus to it
        tabs[index].setAttribute("aria-selected", "true");
        tabs[index].setAttribute("tabindex", "0");
        tabs[index].focus();
        focusedTabIndex = index;
    };
};

// Footer toggle behavior
document
    .querySelectorAll<HTMLElement>(".footer-nav-mobile__header")
    .forEach((elem) => {
        elem.addEventListener("click", () => {
            elem.parentElement?.classList.toggle(
                "footer-nav-mobile__block--active",
            );
        });
    });

// Accordion
document.querySelectorAll<HTMLElement>(".accordion").forEach((elem) => {
    elem.addEventListener("click", (event) => {
        event.preventDefault();
        elem.classList.toggle("accordion--is-active");
        elem.parentElement
            ?.querySelector(".accordion-target")
            ?.classList.toggle("accordion-target--is-active");
        elem.setAttribute(
            "aria-expanded",
            `${elem.classList.contains("accordion--is-active")}`,
        );
    });
});

// Search bar functionality
document.querySelectorAll(".search-results__clear-icon").forEach((elem) => {
    elem.addEventListener("click", () => {
        const input = document.querySelector<HTMLInputElement>(
            ".search-results__search-form--text",
        );
        if (input) {
            input.value = "";
        }
    });
});

// Utility pages
if (document.body.classList.contains("utility-page")) {
    // On load -- Tabs -- Add active class
    document
        .querySelector<HTMLElement>(".tabs__tab")
        ?.classList?.add("tabs__tab--is-active");
    document
        .querySelector<HTMLElement>(".tabs__pane")
        ?.classList?.add("tabs__pane--is-active");

    // Desktop
    // Onload -- left side nav -- Accordion -- add active class
    document
        .querySelector<HTMLElement>(".accordion__list-item--desktop .accordion")
        ?.classList?.add("accordion--is-active");
    document
        .querySelector<HTMLElement>(
            ".accordion__list-item--desktop .accordion-target",
        )
        ?.classList?.add("accordion-target--is-active");

    document.querySelectorAll<HTMLElement>(".accordion").forEach((elem) => {
        elem.setAttribute(
            "aria-expanded",
            `${elem.classList.contains("accordion--is-active")}`,
        );
    });
}

// PDP scroll animation
const pdpStarAnimation = () => {
    if (!document.querySelector("#reviews")) {
        return;
    }
    const controller = new ScrollMagic.Controller({
        globalSceneOptions: {
            duration: 0,
        },
    });
    const scene = new ScrollMagic.Scene({
        triggerElement: "#reviews",
        triggerHook: 0.5,
    });
    // stars animation class
    scene
        .setClassToggle(".star-rating__star", "star-rating__star--active")
        .addTo(controller);
};

// Register functions after body loads
onDOMContentLoaded.on(() => {
    pdpStarAnimation();
    registerEventListeners_Tabs();
});
