import Flickity from "flickity";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

onDOMContentLoaded.on(() => {
    const lpCustomerReview = document.querySelector(
        ".landign-page-customer-review",
    );
    if (!lpCustomerReview) {
        return;
    }
    new Flickity(lpCustomerReview, {
        watchCSS: true,
        initialIndex: 1,
    });
});
