import Flickity from "flickity";

const productListNav = document.querySelector(".reviews-page-nav__list");
if (productListNav) {
    const allNavItems = document.getElementsByClassName(
        "reviews-page-nav__item",
    );
    const currNavItem = document.getElementById(
        "reviews-page-nav__item--current",
    );
    const navArray = Array.prototype.slice.call(allNavItems);
    new Flickity(productListNav, {
        watchCSS: true,
        draggable: true,
        wrapAround: true,
        prevNextButtons: true,
        initialIndex: navArray.indexOf(currNavItem),
    });
}
