import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
//@ts-expect-error: lint disable next line
import { gsap } from "gsap/dist/gsap";
//@ts-expect-error: lint disable next line
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Flickity from "flickity-imagesloaded";

const readyScrollEffect = () => {
    const SCROLL_TRIGGER = "#scroll-trigger";
    const scrollTriggerElem = !document.getElementById(SCROLL_TRIGGER);
    if (!scrollTriggerElem) {
        return;
    }
    gsap.registerPlugin(ScrollTrigger);
    // mobile breakpoint
    const MAX_WIDTH = 768;
    // Initialize GSAP timeline
    const wipeAnimation = gsap.timeline();
    let scrollActive = false;

    const initScrollTriggers = () => {
        const imageClips = document.querySelectorAll<HTMLElement>(
            ".home-page-product-feature__image",
        );
        const copyClips = document.querySelectorAll<HTMLElement>(
            ".home-page-product-feature__copy",
        );
        const contentClips = document.querySelectorAll<HTMLElement>(
            ".home-page-product-feature__content",
        );
        const numChildren = imageClips.length;

        // Define animation properties
        const dist = -100 / numChildren;
        let idx = numChildren;
        let yStart = 0;
        let yDest = dist;

        // Create animation timeline
        while (idx--) {
            const currentImageClip = imageClips[idx];
            const currentContentClip = contentClips[idx];
            const speed = 1;
            if (idx !== 0) {
                wipeAnimation.fromTo(
                    currentImageClip,
                    speed,
                    { autoAlpha: 1 },
                    {
                        autoAlpha: 0,
                        ease: "power2.in",
                        delay: idx === numChildren - 1 ? 1 : 0,
                    },
                );
                wipeAnimation.fromTo(
                    copyClips,
                    speed,
                    { y: `${yStart}%` },
                    { y: `${yDest}%`, ease: "none", delay: 0 },
                    `-=${speed}`,
                );
                wipeAnimation.fromTo(
                    currentContentClip,
                    speed,
                    { autoAlpha: speed },
                    {
                        autoAlpha: 1,
                        ease: "power4.in",
                        delay: 0,
                    },
                    `-=${speed}`,
                );

                yStart += dist;
                yDest += dist;
            }

            scrollActive = true;
        }

        // Set up ScrollTrigger
        ScrollTrigger.create({
            trigger: SCROLL_TRIGGER,
            start: "top top",
            end: "bottom bottom",
            animation: wipeAnimation,
            pin: true,
            scrub: true,
            toggleActions: "play none none reverse",
        });
    };

    if (window.innerWidth >= MAX_WIDTH) {
        initScrollTriggers();
    }

    type ScrollTriggerInstance = {
        kill: () => void;
    };

    window.addEventListener("resize", () => {
        const wWidth = window.innerWidth;
        if (wWidth < MAX_WIDTH) {
            ScrollTrigger.getAll().forEach((trigger: ScrollTriggerInstance) =>
                trigger.kill(),
            );
            wipeAnimation.kill();
            scrollActive = false;
        } else {
            if (scrollActive) {
                return;
            }
            initScrollTriggers();
        }
    });
};

const initFlickity = () => {
    const heroImageList = document.querySelector<HTMLElement>(
        ".home-page-hero-carousel__container",
    );
    if (heroImageList) {
        new Flickity(heroImageList, {
            watchCSS: true,
            autoPlay: true,
            pageDots: false,
        });
    }
    const productFeatureMobile = document.querySelector<HTMLElement>(
        ".home-page-product-feature-mobile__container",
    );
    if (productFeatureMobile) {
        new Flickity(productFeatureMobile, {
            watchCSS: true,
            prevNextButtons: false,
            pageDots: false,
        });
    }
    const customerReview = document.querySelector<HTMLElement>(
        ".customer-review-module__review-list--homepage",
    );
    if (customerReview) {
        new Flickity(customerReview, {
            watchCSS: true,
            initialIndex: 1,
        });
    }
};

onDOMContentLoaded.on(() => {
    readyScrollEffect();
    initFlickity();
});
