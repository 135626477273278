import Flickity from "flickity";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

onDOMContentLoaded.on(() => {
    const lpPromo = document.querySelector(
        ".landing-page-promo__image-container",
    );
    if (!lpPromo) {
        return;
    }
    new Flickity(lpPromo, {
        watchCSS: true,
        prevNextButtons: false,
    });
});
