import React from "react";
import classNames from "classnames";
import Flickity from "flickity-imagesloaded";

export interface ICardSlider {
    imageUrl: string;
    copy: string;
}

interface IProps {
    images: ICardSlider[];
}

interface IState {
    isNormalLayout: boolean;
}

export class CardSlider extends React.Component<IProps, IState> {
    private imageCarousel: HTMLUListElement | null = null;
    private carouselFlkty: Flickity | null = null;

    public state: IState = {
        isNormalLayout: false,
    };

    componentDidMount() {
        const carouselElem = this.imageCarousel;

        if (!carouselElem) {
            return;
        }

        this.recalcFlickityDisplay(carouselElem);
        window.addEventListener("resize", () => {
            this.recalcFlickityDisplay(carouselElem);
        });
    }

    private recalcFlickityDisplay(carousel: HTMLUListElement) {
        const isWindowMobile = window.innerWidth < 501;
        const carouselLength = carousel.querySelectorAll(
            ".card-slider__column",
        ).length;

        if (carouselLength >= 4 || (carouselLength >= 2 && isWindowMobile)) {
            if (this.state.isNormalLayout) {
                this.setState({
                    isNormalLayout: false,
                });
            }
            this.initFlickity(carousel);
        } else {
            if (this.carouselFlkty) {
                this.carouselFlkty.destroy();
            }

            this.setState({
                isNormalLayout: true,
            });
        }
    }

    private initFlickity(carousel: HTMLUListElement) {
        if (this.carouselFlkty) {
            this.carouselFlkty.destroy();
        }

        this.carouselFlkty = new Flickity(carousel, {
            cellAlign: "left",
            wrapAround: true,
            pageDots: true,
            imagesLoaded: true,
            arrowShape: {
                x0: 15,
                x1: 100,
                y1: 110,
                x2: 105,
                y2: 110,
                x3: 20,
            },
        });
    }

    private buildFullImages() {
        const classes = classNames({
            "card-slider__column": true,
            "card-slider__column--normal-layout":
                this.state.isNormalLayout && true,
        });

        const images = this.props.images.map((img, i) => {
            const fullStyle: React.CSSProperties = {
                backgroundImage: `url(${img.imageUrl})`,
            };
            return (
                <li key={i} className={classes}>
                    <div className="card-slider__image" style={fullStyle}></div>
                    <div className="card-slider__copy-container">
                        <div
                            className="card-slider__copy"
                            dangerouslySetInnerHTML={{ __html: img.copy }}
                        ></div>
                    </div>
                </li>
            );
        });
        return images;
    }

    render() {
        const classes = classNames({
            "card-slider__columns": true,
            "card-slider__columns--normal-layout":
                this.state.isNormalLayout && true,
        });

        return (
            <div className="card-slider__carousel-content">
                <ul
                    className={classes}
                    ref={(ref) => {
                        this.imageCarousel = ref;
                    }}
                >
                    {this.buildFullImages()}
                </ul>
            </div>
        );
    }
}

export default CardSlider;
