/**
 * Lightweight implementation of jquery.parents
 *
 * See https://stackoverflow.com/questions/12980877/parents-without-jquery-or-queryselectorall-for-parents
 */
const getParents = (el: HTMLElement) => {
    const parents = [];
    let parent = el.parentNode as HTMLElement;
    while (parent) {
        const node = parent;
        parents.push(node);
        parent = node.parentNode as HTMLElement;
    }
    return parents;
};

/**
 * Activate an element in a block of elements
 * Remove active modifier from sibling elements, then add it to selected element
 *
 * Assumption: you are targeting a set of elements inside of a block
 * Assumption: element has __ in it. We can strip that to get the parent block
 *
 * - target: the specific thing to go after. Should only be one element.
 * - elementClass: pick a specific class. I could set it up so that if nothing is
                   specified it goes after the first class in the target element
 * - activeClassModifier: defaults to '--is-active' but can be overridden
 */
export const switchActiveElement = (
    target: HTMLElement,
    elementClass: string,
    activeClassModifier = "--is-active",
) => {
    const modifier = `${elementClass}${activeClassModifier}`;
    const blockClass = elementClass.split("__")[0];
    getParents(target)
        .filter((parent) => {
            return (
                parent &&
                parent.classList &&
                parent.classList.contains &&
                parent.classList.contains(blockClass)
            );
        })
        .reduce<HTMLElement[]>((memo, parent) => {
            const children = Array.from(
                parent.querySelectorAll<HTMLElement>(`.${elementClass}`),
            );
            return memo.concat(children);
        }, [])
        .forEach((child) => {
            child.classList.remove(modifier);
        });
    target.classList.add(modifier);
};
