import Flickity from "flickity";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

onDOMContentLoaded.on(() => {
    const pdpComparison = document.querySelector(".pdp-comparison__body");
    if (!pdpComparison) {
        return;
    }
    new Flickity(pdpComparison, {
        watchCSS: true,
        autoPlay: false,
    });
});
