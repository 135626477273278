import Flickity from "flickity";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

const readyLandingPageValueProps = () => {
    const iconList = document.querySelectorAll<HTMLElement>(
        ".landing-page-value-props__block",
    );
    const imageList = document.querySelectorAll<HTMLElement>(
        ".landing-page-value-props__image",
    );
    if (iconList.length <= 0 || imageList.length <= 0) {
        return;
    }

    let currentIndex = 0;

    iconList[currentIndex].classList.add("landing-page-value-props__block--on");
    imageList[currentIndex].classList.add(
        "landing-page-value-props__image--on",
    );

    iconList.forEach((elem, index) => {
        elem.addEventListener("click", () => {
            iconList[currentIndex].classList.remove(
                "landing-page-value-props__block--on",
            );
            imageList[currentIndex].classList.remove(
                "landing-page-value-props__image--on",
            );

            iconList[index].classList.add(
                "landing-page-value-props__block--on",
            );
            imageList[index].classList.add(
                "landing-page-value-props__image--on",
            );

            iconList[currentIndex].setAttribute("aria-selected", "false");
            iconList[index].setAttribute("aria-selected", "true");

            currentIndex = index;
        });
    });
};

onDOMContentLoaded.on(() => {
    const lpValueProps = document.querySelector(
        ".landing-page-value-props-mobile__container",
    );
    if (!lpValueProps) {
        return;
    }
    new Flickity(lpValueProps, {
        watchCSS: true,
        prevNextButtons: false,
    });

    readyLandingPageValueProps();
});
