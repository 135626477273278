document
    .querySelectorAll<HTMLElement>(
        ".landing-page-reviews__container--hover-active",
    )
    .forEach((elem) => {
        const hoverElem = elem.querySelector<HTMLElement>(
            ".home-page-reviews-hover",
        );
        if (!hoverElem) {
            return;
        }
        elem.addEventListener("mouseenter", () => {
            hoverElem.style.opacity = "100";
        });
        elem.addEventListener("mouseleave", () => {
            hoverElem.style.opacity = "0";
        });
    });
