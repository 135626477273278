import React from "react";
import { Provider } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import {
    isoReviewsBrandID,
    isoReviewsProductTypeID,
    isoFormUUID,
} from "tsi-common-react/src/models/nominals";
import { ReviewSortOption } from "tsi-common-react/src/models/reviews.interfaces";
import { strToBool } from "tsi-common-react/src/utils/format";
import { unique, notEmpty } from "tsi-common-react/src/utils/functional";
import { Dispatchers } from "tsi-common-react/src/apps/reviews/dispatchers";
import { readQueryParams } from "tsi-common-react/src/apps/reviews/history";
import { store } from "../store";

/**
 * Init facet values from the URL
 */
const dispatchers = new Dispatchers(store.dispatch);
const initialFacetValues = readQueryParams();
if (initialFacetValues.product_type_id) {
    const productIDs = `${initialFacetValues.product_type_id}`
        .split(",")
        .map((ptid) => {
            return ptid
                ? isoReviewsProductTypeID.wrap(parseInt(ptid, 10))
                : null;
        })
        .filter(notEmpty)
        .filter(unique);
    if (productIDs.length) {
        dispatchers.setProductTypes(productIDs);
    }
}
delete initialFacetValues.product_type_id;
dispatchers.setFacetValues(initialFacetValues);

/**
 * Render PDP Reviews Application
 */
dynamicPlaceComponent('[data-place-react="product-reviews"]', async (elem) => {
    const { ProductReviews } = await import(
        "tsi-common-react/src/apps/reviews/containers/ProductReviews"
    );
    const brandID = isoReviewsBrandID.wrap(
        parseInt(elem.dataset.reviewsBrandId || "", 10),
    );
    const productUUIDs = JSON.parse(elem.dataset.reviewsProductUuids || "[]");
    const numReviews = parseInt(elem.dataset.numReviews || "", 10);
    const averageRating = parseFloat(elem.dataset.averageRating || "");
    const showProductsFilter = strToBool(
        elem.dataset.showProductsFilter || "no",
    );
    const showSourcesFilter = strToBool(elem.dataset.showSourcesFilter || "no");
    return (
        <Provider store={store}>
            <ProductReviews
                title={elem.dataset.reviewsTitle || ""}
                brandID={brandID}
                productUUIDs={productUUIDs}
                numReviews={numReviews}
                averageRating={averageRating}
                showWriteReviewButton={true}
                showProductsFilter={showProductsFilter}
                showSourcesFilter={showSourcesFilter}
                writeReviewFormUUID={isoFormUUID.wrap(uuidv4())}
                isCollapsedView={true}
            />
        </Provider>
    );
});

/**
 * Render "Write Review" buttons
 */
dynamicPlaceComponent(
    '[data-place-react="write-review-button"]',
    async (elem) => {
        const { SingleProductCollapsibleWriteReviewForm } = await import(
            "tsi-common-react/src/apps/reviews/containers/SingleProductCollapsibleWriteReviewForm"
        );
        const displayedProductTypeIDWhitelist = JSON.parse(
            elem.dataset.reviewsProductTypeWhitelist || "[]",
        );
        // Update selected product types based on a given type whitelist
        if (displayedProductTypeIDWhitelist.length) {
            const selectedProductTypeIDs = store
                .getState()
                .reviews.ui.productTypeIDs.filter((tid) => {
                    return displayedProductTypeIDWhitelist.includes(tid);
                });
            if (selectedProductTypeIDs.length <= 0) {
                selectedProductTypeIDs.push(displayedProductTypeIDWhitelist[0]);
            }
            dispatchers.setProductTypes(selectedProductTypeIDs);
        }
        return (
            <Provider store={store}>
                <SingleProductCollapsibleWriteReviewForm
                    formUUID={isoFormUUID.wrap(uuidv4())}
                    displayedProductTypeIDWhitelist={
                        displayedProductTypeIDWhitelist
                    }
                />
            </Provider>
        );
    },
);

/**
 * Render ReviewsViewer SPA
 */
dynamicPlaceComponent('[data-place-react="review-spa"]', async (elem) => {
    const { ReviewsViewer } = await import(
        "tsi-common-react/src/apps/reviews/containers/ReviewsViewer"
    );
    const brandID = isoReviewsBrandID.wrap(
        parseInt(elem.dataset.reviewsBrandId || "1", 10),
    );
    const displayedProductTypeIDWhitelist = JSON.parse(
        elem.dataset.reviewsProductTypeWhitelist || "[]",
    );
    const defaultSortOption = (elem.dataset.defaultSort ||
        "-created_datetime") as ReviewSortOption;
    const pageSize = parseInt(elem.dataset.pageSize || "6", 10);
    const showSources =
        (elem.dataset.showSources || "True").toLowerCase() === "true";
    const reviewSourceFilter = showSources ? [] : ["Tempur-Pedic"];
    // Update selected product types based on a given type whitelist
    if (displayedProductTypeIDWhitelist.length) {
        const selectedProductTypeIDs = store
            .getState()
            .reviews.ui.productTypeIDs.filter((tid) => {
                return displayedProductTypeIDWhitelist.includes(tid);
            });
        if (selectedProductTypeIDs.length <= 0) {
            selectedProductTypeIDs.push(displayedProductTypeIDWhitelist[0]);
        }
        dispatchers.setProductTypes(selectedProductTypeIDs);
    }
    return (
        <Provider store={store}>
            <ReviewsViewer
                brandID={brandID}
                defaultSortOption={defaultSortOption}
                displayedProductTypeIDWhitelist={
                    displayedProductTypeIDWhitelist
                }
                pageSize={pageSize}
                showAppliedFilters={false}
                showSources={showSources}
                sourceFilterWhitelist={reviewSourceFilter}
            />
        </Provider>
    );
});
